import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import Popup from '@components/Popups';
import Header from '@sections/Header';
import Footer from '@sections/Footer';
import Main from '@sections/Main';
import Heading from '@sections/Heading';
import Error from '@/src/features/utilities/Error';
import { useAppDispatch, useAppSelector } from './hooks';
import { setColorScheme, selectColorScheme } from './userSlice';
import ccn from '../utils/createClassName';

const tailwindDefaults = ccn`dark:bg-gray-900 dark:text-white`;
const darkModeMatch = () => window.matchMedia('(prefers-color-scheme: dark)');

interface IProps {
  isTest?: boolean;
}

export function AppContent({ isTest }: IProps) {
  const dispatch = useAppDispatch();
  const [popup1Open, setPopup1Open] = useState(true);
  const colorScheme = useAppSelector(selectColorScheme);

  const darkModeListener = useCallback(
    (event: MediaQueryListEvent) => {
      dispatch(setColorScheme(event.matches ? 'dark' : 'light'));
    },
    [dispatch],
  );

  /** null operator > darkModeMatch()? < is used to avoid testing errors */
  useEffect(() => {
    darkModeMatch()?.addEventListener('change', darkModeListener);
    dispatch(setColorScheme(darkModeMatch()?.matches ? 'dark' : 'light'));
    return darkModeMatch()?.removeEventListener('change', darkModeListener);
  }, [dispatch, darkModeListener]);

  useEffect(() => {
    if (colorScheme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [colorScheme]);

  return (
    <div className={tailwindDefaults}>
      <ErrorBoundary fallback={<Error />}>
        {popup1Open && <Popup onClose={() => setPopup1Open(false)} />}
        <Header />
        <Fade>
          <Main isTest={isTest || false} />
        </Fade>
        <Fade>
          <Footer />
        </Fade>
      </ErrorBoundary>
    </div>
  );
}

AppContent.defaultProps = {
  isTest: false,
};

/** HelmetProvider is here instead of main.tsx to avoid testing errors */
function App() {
  return (
    <HelmetProvider>
      <Router>
        <Heading pageUrl="" pageTitle="" />
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
