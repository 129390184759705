// import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/16/solid';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NavHashLink } from 'react-router-hash-link';
import DarkModeToggle from '@components/Buttons/DarkModeToggle';
import ROUTES, { ActiveLink } from '@/src/app/routes';
import ccn from '@/src/utils/createClassName';

const navlinkClassname = ccn`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 
text-gray-900 hover:bg-gray-50 dark:text-gray-50 dark:hover:bg-gray-700`;

const navlinkPrimary = (name: string, route: string) => {
  return (
    <NavHashLink key={uuidv4()} to={route} className={ActiveLink}>
      {name}
    </NavHashLink>
  );
};

const navlinkMobile = (
  name: string,
  route: string,
  onClick: React.MouseEventHandler<HTMLAnchorElement>,
) => {
  return (
    <NavHashLink
      key={uuidv4()}
      to={route}
      onClick={onClick}
      className={navlinkClassname}
    >
      {name}
    </NavHashLink>
  );
};

interface PageLink {
  name: string;
  path: string;
}

const pages: PageLink[] = [
  {
    name: 'Home',
    path: ROUTES.getHome(),
  },
  {
    name: 'Projects',
    path: ROUTES.getProjects(),
  },
  {
    name: 'Resum\u00E9',
    path: ROUTES.getResume(),
  },
  {
    name: 'Services',
    path: ROUTES.getServices(),
  },
];

const navigation = pages.map((page) => navlinkPrimary(page.name, page.path));

const siteLogo = (
  onClick?: React.MouseEventHandler<HTMLAnchorElement>, // | undefined
  className?: string, // | undefined
) => (
  <NavHashLink to={ROUTES.getHome()} onClick={onClick} className={className}>
    <span className="sr-only">Wesley Coburn</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      viewBox="0 0 375 375"
      height="75"
      version="1.0"
      fill="currentColor"
    >
      <defs>
        <clipPath id="a">
          <path d="M187.5 375c-50.059 0-97.195-19.484-132.57-54.93C19.484 284.695 0 237.56 0 187.5S19.484 90.305 54.93 54.93C90.305 19.484 137.44 0 187.5 0s97.195 19.484 132.57 54.93C355.516 90.379 375 137.44 375 187.5s-19.484 97.195-54.93 132.57C284.695 355.516 237.56 375 187.5 375zm0-363.758c-47.063 0-91.352 18.36-124.625 51.633C29.602 96.148 11.242 140.438 11.242 187.5c0 47.063 18.36 91.352 51.633 124.625 33.273 33.273 77.563 51.633 124.625 51.633 47.063 0 91.352-18.36 124.625-51.633 33.273-33.273 51.633-77.563 51.633-124.625 0-47.063-18.36-91.352-51.633-124.625-33.273-33.273-77.563-51.633-124.625-51.633zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="M-37.5-37.5h450v450h-450z" />
      </g>
      <path d="m93.529 185.88 16.273 44.578h9.391l31.223-94.055h-14.024c-6.879 21.031-15.082 45.64-22.355 67.73L98.955 161.01H88.24l-15.215 43.257-22.488-67.863h-13.89l31.483 94.055h9.395c4.762-14.02 10.715-29.5 16.004-44.578zM230.445 183.895c0 12.832 5.555 24.606 13.493 33.07 8.07 8.47 19.71 13.626 32.277 13.626 21.566 0 38.496-14.286 43.79-35.32h-13.099c-4.761 14.023-16.668 22.093-30.691 22.093-8.863 0-17.727-3.707-23.547-9.789-5.82-6.086-9.258-14.422-9.258-23.68 0-9.527 3.57-17.726 9.39-23.812 5.821-6.086 14.552-10.32 23.415-10.32 13.629 0 25.004 7.41 29.898 21.3h13.23c-5.292-20.902-21.96-34.527-43.128-34.527-12.566 0-24.605 5.82-32.676 14.152a48.447 48.447 0 0 0-13.094 33.207zm0 0" />
      <path
        fill="none"
        d="M187.5 262.477V112.523"
        stroke="currentColor"
        strokeWidth="12.75"
      />
    </svg>
  </NavHashLink>
);

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(window.scrollY);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
    setMobileMenuOpen(false);
  };

  const navigationMobile = pages.map((page) =>
    navlinkMobile(page.name, page.path, () => setMobileMenuOpen(false)),
  );

  const gradientClassname = ccn`bg-gradient-to-b from-gray-100/70 via-gray-100 to-gray-200/70 bg-size-200 
  bg-pos-100 dark:from-gray-700/70 dark:via-gray-700 dark:to-gray-900/70`;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`sticky inset-x-0 top-0 z-40 transition-all duration-700 ${scrollY !== 0 ? gradientClassname : 'bg-pos-0'}`}
    >
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex text-black transition-none dark:text-white lg:flex-1">
          {siteLogo()}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">{navigation}</div>
        <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:gap-x-16">
          <DarkModeToggle />
          <div className="hidden flex-shrink-0 items-center lg:flex">
            <button
              type="button"
              onClick={scrollToBottom}
              className="self-center rounded px-8 py-3 text-lg font-semibold hover:ring focus:ring dark:bg-violet-600 dark:text-gray-50"
            >
              Contact
            </button>
          </div>
        </div>
      </nav>
      {/* start mobile menu */}
      <div className="lg:hidden">
        <div
          className={`fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 
          py-6 transition-transform duration-500 dark:bg-gray-900 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 ${mobileMenuOpen ? '' : 'translate-x-full transform'}`}
        >
          <div className="flex items-center justify-between text-black dark:text-white">
            {siteLogo(() => setMobileMenuOpen(false), '-m-1.5 p-1.5')}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 dark:text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex justify-center pb-6">
                <DarkModeToggle />
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={scrollToBottom}
                  className="self-center rounded px-8 py-3 text-lg font-semibold hover:ring focus:ring dark:bg-violet-600 dark:text-gray-50"
                >
                  Contact
                </button>
              </div>
              <div className="space-y-2 py-6">{navigationMobile}</div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 dark:bg-gray-900 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between dark:text-white">
            {homeLink(() => setMobileMenuOpen(false), '-m-1.5 p-1.5')}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 dark:text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex justify-center pb-6">
                <DarkModeToggle />
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={scrollToBottom}
                  className="self-center rounded px-8 py-3 text-lg font-semibold hover:ring focus:ring dark:bg-violet-600 dark:text-gray-50"
                >
                  Contact
                </button>
              </div>
              <div className="space-y-2 py-6">{navigationMobile}</div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      */}
    </header>
  );
}
