import { NavHashLink } from 'react-router-hash-link';
import ROUTES from '@/src/app/routes';
import Profile from '../Profile';

export default function CallToAction() {
  return (
    <div className="mx-auto min-h-dvh max-w-2xl px-6 py-4 sm:py-8 lg:px-8 lg:py-16">
      <div className="hidden sm:mb-8 sm:flex sm:justify-center">
        <div className="light:text-gray-600 relative rounded-full px-3 py-1 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
          Now available for freelance web services.{' '}
          <NavHashLink
            to={ROUTES.getServices()}
            className="font-semibold text-indigo-600 dark:text-indigo-400"
          >
            <span className="absolute inset-0" aria-hidden="true" />
            View services <span aria-hidden="true">&rarr;</span>
          </NavHashLink>
        </div>
      </div>
      <div className="text-center">
        <Profile />
        <p className="light:text-gray-600 mt-6 text-lg leading-8">
          I am a versatile Front-End Web Developer adept in HTML, CSS,
          JavaScript, and TypeScript, as well as modern frameworks like React
          and Redux.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <NavHashLink
            to={ROUTES.getProjects()}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            View projects
          </NavHashLink>
          <NavHashLink
            to={ROUTES.getResume()}
            className="light:text-gray-900 text-sm font-semibold leading-6"
          >
            View resume <span aria-hidden="true">&rarr;</span>
          </NavHashLink>
        </div>
      </div>
    </div>
  );
}
