// eslint-disable-next-line import/extensions
import webServices from '@/src/assets/images/web_services.png';

/**
 * call to action - content and offset image
 * @link https://www.hyperui.dev/components/marketing/ctas
 */
export default function CallToAction() {
  return (
    <section className="overflow-hidden sm:grid sm:grid-cols-2 sm:items-center">
      <div className="p-8 md:p-12 lg:px-16 lg:py-24">
        <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white md:text-3xl">
            Now available for freelance web services
          </h2>

          <p className="hidden text-gray-500 dark:text-gray-400 md:mt-4 md:block">
            Ready to elevate your online presence? Let&apos;s craft your dream
            website together! Contact me today for expert freelance web
            services.
          </p>

          <div className="mt-4 md:mt-8">
            <a
              href="https://calendly.com/wescoburn/15min"
              target="_blank"
              rel="noreferrer"
              className="inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"
            >
              Schedule a Call
            </a>
          </div>
        </div>
        {/* visual fix for grid spacing */}
        <div className="invisible xl:h-56" />
      </div>

      <img
        alt="Violin"
        src={webServices}
        className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
      />
    </section>
  );
}
