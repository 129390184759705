/* eslint-disable import/extensions */
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import resumePDF from '@/src/assets/files/Wesley_Coburn_resume.pdf';
import pdfIcon from '@/src/assets/images/pdf_icon.svg';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export default function Resume() {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  );

  useEffect(() => {
    window
      .matchMedia('(min-width: 768px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    /*
    <embed
      src={resumePDF}
      type="application/pdf"
      className="mx-auto w-[50px] py-32 md:h-[1094px] md:w-[300px]"
    />
    */
    /*
    <iframe
      title="Resume"
      src={resumePDF}
      className="mx-auto h-[1250px] w-[800px] px-16 py-32 lg:h-[2500px] lg:w-[1000px]"
    />
    */
    /*
    <object
      data={resumePDF}
      type="application/pdf"
      className="mx-auto h-[700px] w-screen px-16 py-32 md:h-[2100px] md:px-32 xl:h-[2500px] xl:px-80"
      height="100%"
    >
      <p>
        <a href={resumePDF} target="_blank" rel="noreferrer">
          Your web browser doesn&apos;t have a PDF plugin. Instead you can click
          here to download the PDF file.
          <img src={pdfIcon} alt="PDF Icon" className="pt-6" />
        </a>
      </p>
    </object>
    */
    <>
      <Document file={resumePDF}>
        <Page
          pageNumber={1}
          className="flex justify-center"
          scale={matches ? 1.7 : 0.6}
        />
        <Page
          pageNumber={2}
          className="flex justify-center"
          scale={matches ? 1.7 : 0.6}
        />
      </Document>
      <div className="relative bottom-52 z-10 flex justify-center text-black md:bottom-96">
        <Fade delay={300} triggerOnce>
          <a href={resumePDF} target="_blank" rel="noreferrer">
            <div className="flex justify-center rounded-full bg-gray-700 transition-all hover:bg-gray-400">
              <img src={pdfIcon} alt="PDF Icon" className="py-6" />
            </div>
            <p className="text-center font-bold">Download as a PDF</p>
          </a>
        </Fade>
      </div>
    </>
  );
}
