import { Routes, Route, Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Fade } from 'react-awesome-reveal';
import CallToAction from '@components/CallToAction';
import LinkGrid from '@components/LinkGrid';
import Resume from '@components/Resume';
import Services from '@components/Services';
import Timeline from '@components/Timeline';
import NotFound from '@/src/features/utilities/NotFound';
import ROUTES, { PATHS } from '@/src/app/routes';
import Heading from '../Heading';

const pageWrapper = (
  content: JSX.Element,
  pageUrl: string,
  pageTitle: string,
  includeHeading: boolean,
) => {
  return (
    <>
      {includeHeading && <Heading pageUrl={pageUrl} pageTitle={pageTitle} />}
      <main role="main">
        <Fade triggerOnce>{content}</Fade>
      </main>
    </>
  );
};

interface Page {
  path: string;
  element: (isTest: boolean) => JSX.Element;
}

const pages: Page[] = [
  {
    path: PATHS.home(),
    element: (isTest: boolean) =>
      pageWrapper(<CallToAction />, 'home', 'Home', isTest),
  },
  {
    path: PATHS.resume(),
    element: (isTest: boolean) =>
      pageWrapper(
        <>
          <Timeline />
          <Resume />
        </>,
        'resume',
        'Resume',
        isTest,
      ),
  },
  {
    path: PATHS.projects(),
    element: (isTest: boolean) =>
      pageWrapper(<LinkGrid />, 'projects', 'Projects', isTest),
  },
  {
    path: PATHS.services(),
    element: (isTest: boolean) =>
      pageWrapper(<Services />, 'services', 'Services', isTest),
  },
];

interface IProps {
  isTest: boolean;
}
export default function Main({ isTest }: IProps) {
  return (
    <Routes>
      <Route
        path={PATHS.ROOT()}
        element={<Navigate to={ROUTES.getHome()} replace />}
      />
      {pages.map((page) => {
        return (
          <Route
            key={uuidv4()}
            path={page.path}
            element={page.element(!isTest)}
          />
        );
      })}
      <Route path={PATHS.NOT_FOUND()} element={<NotFound />} />
    </Routes>
  );
}
