import { NavHashLink } from 'react-router-hash-link';
import { v4 as uuidv4 } from 'uuid';
import { Fade } from 'react-awesome-reveal';
import ROUTES from '@/src/app/routes';

interface Link {
  name: string;
  description: Array<string>;
  url: string;
  sameSite: boolean;
}

const linkTile = ({ name, description, url, sameSite }: Link) => {
  return (
    <a
      key={uuidv4()}
      className="block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10"
      href={url}
      target={sameSite ? '_self' : '_blank'}
      rel={sameSite ? '' : 'noreferrer'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 text-pink-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M12 14l9-5-9-5-9 5 9 5z" />
        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
        />
      </svg>

      <h2 className="mt-4 text-xl font-bold">{name}</h2>

      <div className="mt-1 text-sm dark:text-gray-300">
        <ul className="list-[square]">
          {description.map((point) => (
            <li key={uuidv4()}>{point}</li>
          ))}
        </ul>
      </div>
    </a>
  );
};

/*
const skills = [
  ['HTML', 'CSS', 'JavaScript', 'TypeScript'],
  ['React.js', 'Redux', 'React-Router', 'TailwindCSS'],
  ['Unit Testing', 'Responsive Design', 'GIT'],
];
*/

interface Project {
  name: string;
  description: Array<string>;
  deployLinkUrl?: string;
  codeLinkUrl?: string;
  sameSite?: boolean;
}

const webAppProjects: Array<Project> = [
  {
    name: 'Portfolio (this site)',
    description: [
      'Created a responsiove portfolio using TypeScript, React, Redux, React Router, and TailwindCSS.',
      'Implemented Vitest to write extensive unit tests and automate test execution.',
      'Ensured accessibility compliance through adherence to the popular Airbnb ESLint configuration.',
      'Used GitHub and Render to construct a continuous integration/deployment pipeline.',
    ],
    codeLinkUrl: 'https://wescoburn.dev',
    sameSite: true,
  },
  {
    name: 'Note Taker',
    description: [
      'Developed a full-stack MERN app using MongoDB, Express, React, and Node, with TypeScript, Redux, React Router and TailwindCSS',
      'Utilized Vite for a streamlined build process and performed unit testing using Vitest and React Testing Library.',
      'Implemented JSON Web Tokens for secure authentication and authorization.',
    ],
    deployLinkUrl: 'https://note-taker-1ej3.onrender.com',
    codeLinkUrl: 'https://github.com/Wes-Coburn/note-taker',
  },
];

const gameProjects: Array<Project> = [
  {
    name: 'Drifter Deckbuilding Game',
    description: [
      'Independently conceptualized, designed, and developed a game with Unity and C#',
      'Applied OOP fundamentals like encapsulation, inheritance, and abstraction.',
      'Performed extensive manual testing to find and eliminate bugs.',
    ],
    deployLinkUrl: 'https://weslex555.itch.io/drifter-deckbuilding-game',
    codeLinkUrl: 'https://github.com/Wes-Coburn/Drifter-Deckbuilding-Game',
  },
];

const linksGroup: JSX.Element[] = [];
webAppProjects.forEach((link) =>
  linksGroup.push(
    linkTile({
      name: link.name,
      description: link.description,
      url: link.deployLinkUrl || link.codeLinkUrl || '',
      sameSite: link.sameSite || false,
    }),
  ),
);
gameProjects.forEach((link) =>
  linksGroup.push(
    linkTile({
      name: link.name,
      description: link.description,
      url: link.deployLinkUrl || link.codeLinkUrl || '',
      sameSite: link.sameSite || false,
    }),
  ),
);

/**
 * section - content with link grid and call to action
 * @link https://www.hyperui.dev/components/marketing/sections
 */
export default function LinkGrid() {
  return (
    <div
      id="portfolio-projects"
      className="mx-auto min-h-dvh max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16"
    >
      <div className="mx-auto max-w-lg text-center">
        <h2 className="text-3xl font-bold sm:text-4xl">Projects</h2>

        <p className="mt-4 dark:text-gray-300">
          My projects focus on ReactJS and TypeScript, including a full-stack
          app made with ExpressJS, as well as an adventure game developed in
          Unity.
        </p>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        <Fade cascade damping={0.3} triggerOnce>
          {linksGroup}
        </Fade>
      </div>

      <div className="mt-12 text-center">
        <NavHashLink
          to={ROUTES.getServices()}
          className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Contact for Services
        </NavHashLink>
      </div>
    </div>
  );
}
