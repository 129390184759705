import Contact from '@components/Contact';

/**
 * footer component - simple row
 *
 * @link https://www.hyperui.dev/components/marketing/footers
 */
export default function Footer() {
  return (
    <footer>
      <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
        <Contact />
        <p className="text-xs text-gray-500 dark:text-gray-400">
          &copy; 2024. Wesley Coburn. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
