const publicURL = () => `${import.meta.env.VITE_PUBLIC_URL}`;
const protocol = () => 'https';
// const mobileDomain = 'm.';

export const ActiveLink = ({
  isActive,
  isPending,
}: {
  isActive: boolean;
  isPending: boolean;
  // eslint-disable-next-line no-nested-ternary
}) => (isPending ? 'Pending-link' : isActive ? 'Active-link' : '');

export const CANONICAL = {
  desktop: (path: string) =>
    encodeURI(`${protocol()}://${publicURL()}/${path}`),
  /* mobile: (path: string) =>
    encodeURI(`${protocol()}://${mobileDomain}${publicURL()}/${path}`),
  */
};

export const PATHS = {
  ROOT: () => '',
  NOT_FOUND: () => '*',
  home: () => 'home',
  projects: () => 'projects',
  resume: () => 'resume',
  services: () => 'services',
};

// const absolutePath = (route: string) => `/${route}`;

const ROUTES = {
  getHome: () => `/${PATHS.home()}#`,
  getProjects: () => `/${PATHS.projects()}#`,
  getResume: () => `/${PATHS.resume()}#`,
  getServices: () => `/${PATHS.services()}#`,
};

export default ROUTES;
