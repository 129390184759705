import { Fade } from 'react-awesome-reveal';
import { v4 as uuidv4 } from 'uuid';

interface IEvent {
  title: string;
  date: string;
  description: string;
}

function Event({ title, date, description }: IEvent) {
  return (
    <div className="flex flex-col before:bg-violet-600 sm:relative sm:before:absolute sm:before:left-[-35px] sm:before:top-2 sm:before:z-[1] sm:before:h-4 sm:before:w-4 sm:before:rounded-full">
      <h3 className="text-xl font-semibold tracking-wide">{title} </h3>
      <time className="text-xs uppercase tracking-wide dark:text-gray-400">
        {date}
      </time>
      <p className="mt-3">{description}</p>
    </div>
  );
}

const events: IEvent[] = [
  {
    title: 'Began work as a Freelance Web Developer',
    date: 'Feb 2024',
    description:
      'Started working as a Freelance Web Developer, taking on diverse projects and honing my skills in web development.',
  },
  {
    title: 'Provided SEO consulting to ShesMyDaughter',
    date: 'November 2023',
    description:
      'Offered SEO consulting services to ShesMyDaughter, optimizing their online presence and visibility.',
  },
  {
    title: 'Developed first full-stack app',
    date: 'Oct 2023',
    description:
      'Created my first full-stack application, leveraging both frontend and backend technologies to deliver a comprehensive solution.',
  },
  {
    title: 'Completed Frontend Engineering Pathway on CodeAcademy',
    date: 'Aug 2023',
    description:
      'Successfully completed the Frontend Engineering Pathway on CodeAcademy, enhancing my proficiency in frontend development.',
  },
  {
    title: 'Finished development of Drifter, a Unity game',
    date: 'Feb 2023',
    description:
      'Successfully completed the development of Drifter, a deckbuilding adventure Unity game, showcasing my skills in game development and project management.',
  },
];

export default function Timeline() {
  return (
    <section className="min-h-dvh dark:text-gray-100">
      <div className="container mx-auto max-w-5xl px-4 py-12">
        <div className="mx-4 grid gap-4 sm:grid-cols-12">
          <div className="col-span-12 sm:col-span-3">
            <div className="mb-14 text-center before:mx-auto before:mb-5 before:block before:h-3 before:w-24 before:rounded-md before:bg-violet-600 sm:text-left sm:before:mx-0">
              <h3 className="text-3xl font-semibold">Wesley Coburn</h3>
              <span className="text-sm font-bold uppercase tracking-wider dark:text-gray-400">
                Web Developer
              </span>
            </div>
          </div>
          <div className="relative col-span-12 space-y-6 px-4 sm:col-span-9">
            <div className="relative col-span-12 space-y-12 px-4 before:bg-gray-600 before:dark:bg-gray-300 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:-left-3 sm:before:bottom-0 sm:before:top-2 sm:before:w-0.5">
              <Fade cascade damping={0.4} triggerOnce>
                {events.map((event) => (
                  <Event
                    key={uuidv4()}
                    title={event.title}
                    date={event.date}
                    description={event.description}
                  />
                ))}
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
