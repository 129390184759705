import { useState } from 'react';
import emailjs from '@emailjs/browser';

/* https://mambaui.com/components/contact */
export default function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState('');

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);

    const form = e.currentTarget as HTMLFormElement;

    emailjs
      .sendForm(
        import.meta.env.VITE_REACT_APP_SERVICE_ID,
        import.meta.env.VITE_REACT_APP_TEMPLATE_ID,
        form,
        import.meta.env.VITE_REACT_APP_PUBLIC_KEY,
      )
      .then(
        () => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage('');
          }, 5000); // hide message after 5 seconds
        },
        () => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage('');
          }, 5000); // hide message after 5 seconds
        },
      );

    // Clears the form after sending the email
    form.reset();
  };

  return (
    <section id="contact-form" className="py-6 dark:text-gray-50">
      <div className="mx-auto grid max-w-6xl grid-cols-1 px-6 md:grid-cols-2 md:divide-x lg:px-8">
        <div className="py-6 md:px-6 md:py-0">
          <h1 className="text-4xl font-bold">Get in touch</h1>
          <p className="pb-4 pt-2">Fill in the form to start a conversation</p>
          <div className="space-y-4">
            <p className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="mr-2 h-5 w-5 sm:mr-6"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
              <span>San Francisco Bay Area</span>
            </p>
            <p className="flex items-center">
              <a href="tel:+17075823074" className="hover:dark:text-violet-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="mr-2 inline h-5 w-5 sm:mr-6"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <span>707.582.3074</span>
              </a>
            </p>
            <p className="flex items-center">
              <a
                href="mailto:wgcoburn@outloo.com"
                className="hover:dark:text-violet-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="mr-2 inline h-5 w-5 sm:mr-6"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span>wgcoburn@outlook.com</span>
              </a>
            </p>
            <p className="flex items-center">
              <a
                href="https://linkedin.com/in/wescoburn"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="hover:dark:text-violet-400"
              >
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 mr-2 inline h-4 w-4 fill-current sm:mr-6"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
                <span>linkedin.com/in/wescoburn</span>
              </a>
            </p>
            <p className="flex items-center">
              <a
                href="https://github.com/Wes-Coburn"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="hover:dark:text-violet-400"
              >
                <svg
                  viewBox="0 0 496 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 mr-2 inline h-4 w-4 fill-current sm:mr-6"
                >
                  <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                </svg>
                <span>github.com/wes-coburn</span>
              </a>
            </p>
          </div>
        </div>
        <form
          onSubmit={sendEmail}
          className="flex flex-col space-y-6 py-6 md:px-6 md:py-0"
        >
          <label className="block" htmlFor="user_name">
            <span className="mb-1">Full name</span>
            <input
              required
              type="text"
              id="user_name"
              name="user_name"
              placeholder="Leroy Jenkins"
              className="block w-full rounded-md shadow-sm focus:ring dark:bg-gray-800"
            />
          </label>
          <label className="block" htmlFor="user_email">
            <span className="mb-1">Email address</span>
            <input
              required
              type="email"
              id="user_email"
              name="user_email"
              placeholder="leroy@jenkins.com"
              className="block w-full rounded-md shadow-sm focus:ring dark:bg-gray-800"
            />
          </label>
          <label className="block" htmlFor="message">
            <span className="mb-1">Message</span>
            <textarea
              required
              id="message"
              name="message"
              rows={3}
              className="block w-full rounded-md focus:ring dark:bg-gray-800"
            />
          </label>
          <button
            type="submit"
            value="Send"
            disabled={isSubmitting}
            className="self-center rounded px-8 py-3 text-lg font-semibold hover:ring focus:ring dark:bg-violet-600 dark:text-gray-50"
          >
            Submit
          </button>
          {stateMessage && <p>{stateMessage}</p>}
        </form>
      </div>
    </section>
  );
}
